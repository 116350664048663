import * as React from "react"
import Seo from "../components/seo"
import useBlogPosts from "../hooks/useBlogPosts"

const blogg = () => {
  const { allBloggPosts } = useBlogPosts()
  return (
    <>
      <Seo title="Blogg" />
      <div id="blogg-page">
        <div className="heading-box">
          <h2 className="heading-primary">Blogg</h2>
          <p className="p-main-large">
            Hér finnur þú greinar, ljóð, þankaganga og annað sem við kemur mínum
            hugarheimi.
          </p>
        </div>
        <div className="blogg-container">
          {/* <input
            className="blogg-search-input"
            placeholder="Leita.."
            name="q"
            type="text"
            aria-autocomplete="both"
            aria-haspopup="false"
            role="combobox"
            title="Leita"
            aria-label="Leita"
          ></input> */}
          <div className="blogg-posts-container">{allBloggPosts()}</div>
        </div>
      </div>
    </>
  )
}

export default blogg
